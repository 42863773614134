.container
    margin-right auto
    margin-left auto
    padding-right 15px
    padding-left 15px
    width 100%

@media (min-width: $grid-sm)
    .container
        max-width $container-sm

@media (min-width: $grid-md)
    .container
        max-width $container-md

@media (min-width: $grid-lg)
    .container
        max-width $container-lg

@media (min-width: $grid-xl)
    .container
        max-width $container-xl