.alert
    padding 0.75rem 1.25rem
    margin-bottom 1rem
    border 1px solid transparent
    border-radius 0.25rem

.alert-heading
    color inherit

.alert-link
    font-weight $font-weight-bold

.alert-dismissible
    .close
        position relative
        top -0.75rem
        right -1.25rem
        padding 0.75rem 1.25rem
        color inherit

.alert-primary
    color #004085
    background-color #cce5ff
    border-color #b8daff
    hr
        border-top-color #9fcdff
    .alert-link
        color #002752

.alert-secondary
    color #464a4e
    background-color #e7e8ea
    border-color #dddfe2
    hr
        border-top-color #cfd2d6
    .alert-link
        color #2e3133

.alert-success
    color #155724
    background-color #d4edda
    border-color #c3e6cb
    hr
        border-top-color #b1dfbb
    .alert-link
        color #0b2e13

.alert-info
    color #0c5460
    background-color #d1ecf1
    border-color #bee5eb
    hr
        border-top-color #abdde5
    .alert-link
        color #062c33

.alert-warning
    color #856404
    background-color #fff3cd
    border-color #ffeeba
    hr
        border-top-color #ffe8a1
    .alert-link
        color #533f03

.alert-danger
    color #721c24
    background-color #f8d7da
    border-color #f5c6cb
    hr
        border-top-color #f1b0b7
    .alert-link
        color #491217

.alert-light
    color #818182
    background-color #fefefe
    border-color #fdfdfe
    hr
        border-top-color #ececf6
    .alert-link
        color #686868

.alert-dark
    color #1b1e21
    background-color #d6d8d9
    border-color #c6c8ca
    hr
        border-top-color #b9bbbe
    .alert-link
        color #040505
