.bg-primary
    background-color $primary !important

a.bg-primary:focus, a.bg-primary:hover
    background-color $primaryHov !important

.bg-secondary
    background-color $secondary !important

a.bg-secondary:focus, a.bg-secondary:hover
    background-color $secondaryHov !important

.bg-success
    background-color $success !important

a.bg-success:focus, a.bg-success:hover
    background-color $successHov !important

.bg-info
    background-color $info !important

a.bg-info:focus, a.bg-info:hover
    background-color $infoHov !important

.bg-warning
    background-color $warning !important

a.bg-warning:focus, a.bg-warning:hover
    background-color $warningHov !important

.bg-danger
    background-color $danger !important

a.bg-danger:focus, a.bg-danger:hover
    background-color $dangerHov !important

.bg-light
    background-color $light !important

a.bg-light:focus, a.bg-light:hover
    background-color $lightHov !important

.bg-dark
    background-color $dark !important

a.bg-dark:focus, a.bg-dark:hover
    background-color $darkHov !important

.bg-white
    background-color $white !important

.bg-transparent
    background-color transparent !important