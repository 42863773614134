.align-content-start
    -ms-flex-line-pack start !important
    align-content flex-start !important

.align-content-end
    -ms-flex-line-pack end !important
    align-content flex-end !important

.align-content-center
    -ms-flex-line-pack center !important
    align-content center !important

.align-content-between
    -ms-flex-line-pack justify !important
    align-content space-between !important

.align-content-around
    -ms-flex-line-pack distribute !important
    align-content space-around !important

.align-content-stretch
    -ms-flex-line-pack stretch !important
    align-content stretch !important
	
@media (min-width: $grid-sm)
    .align-content-sm-start
        -ms-flex-line-pack start !important
        align-content flex-start !important
    .align-content-sm-end
        -ms-flex-line-pack end !important
        align-content flex-end !important
    .align-content-sm-center
        -ms-flex-line-pack center !important
        align-content center !important
    .align-content-sm-between
        -ms-flex-line-pack justify !important
        align-content space-between !important
    .align-content-sm-around
        -ms-flex-line-pack distribute !important
        align-content space-around !important
    .align-content-sm-stretch
        -ms-flex-line-pack stretch !important
        align-content stretch !important
		
@media (min-width: $grid-md)
    .align-content-md-start
        -ms-flex-line-pack start !important
        align-content flex-start !important
    .align-content-md-end
        -ms-flex-line-pack end !important
        align-content flex-end !important
    .align-content-md-center
        -ms-flex-line-pack center !important
        align-content center !important
    .align-content-md-between
        -ms-flex-line-pack justify !important
        align-content space-between !important
    .align-content-md-around
        -ms-flex-line-pack distribute !important
        align-content space-around !important
    .align-content-md-stretch
        -ms-flex-line-pack stretch !important
        align-content stretch !important

@media (min-width: $grid-lg)
    .align-content-lg-start
        -ms-flex-line-pack start !important
        align-content flex-start !important
    .align-content-lg-end
        -ms-flex-line-pack end !important
        align-content flex-end !important
    .align-content-lg-center
        -ms-flex-line-pack center !important
        align-content center !important
    .align-content-lg-between
        -ms-flex-line-pack justify !important
        align-content space-between !important
    .align-content-lg-around
        -ms-flex-line-pack distribute !important
        align-content space-around !important
    .align-content-lg-stretch
        -ms-flex-line-pack stretch !important
        align-content stretch !important

@media (min-width: $grid-xl)
    .align-content-xl-start
        -ms-flex-line-pack start !important
        align-content flex-start !important
    .align-content-xl-end
        -ms-flex-line-pack end !important
        align-content flex-end !important
    .align-content-xl-center
        -ms-flex-line-pack center !important
        align-content center !important
    .align-content-xl-between
        -ms-flex-line-pack justify !important
        align-content space-between !important
    .align-content-xl-around
        -ms-flex-line-pack distribute !important
        align-content space-around !important
    .align-content-xl-stretch
        -ms-flex-line-pack stretch !important
        align-content stretch !important