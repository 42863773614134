.clearfix
    &::after
        display block
        clear both
        content ""

.fixed-top
    position fixed
    top 0
    right 0
    left 0
    z-index 1030

.fixed-bottom
    position fixed
    right 0
    bottom 0
    left 0
    z-index 1030

.sr-only
    position absolute
    width 1px
    height 1px
    padding 0
    overflow hidden
    clip rect(0, 0, 0, 0)
    white-space nowrap
    -webkit-clip-path inset(50%)
    clip-path inset(50%)
    border 0

.sr-only-focusable:active, .sr-only-focusable:focus
    position static
    width auto
    height auto
    overflow visible
    clip auto
    white-space normal
    -webkit-clip-path none
    clip-path none

@supports ((position: -webkit-sticky) or (position: sticky))
    .sticky-top 
        position -webkit-sticky
        position sticky
        top: 0
        z-index 1020