.justify-content-start
    -ms-flex-pack start !important
    justify-content flex-start !important

.justify-content-end
    -ms-flex-pack end !important
    justify-content flex-end !important

.justify-content-center
    -ms-flex-pack center !important
    justify-content center !important

.justify-content-between
    -ms-flex-pack justify !important
    justify-content space-between !important

.justify-content-around
    -ms-flex-pack distribute !important
    justify-content space-around !important
	
	
@media (min-width: $grid-sm)
    .justify-content-sm-start
        -ms-flex-pack start !important
        justify-content flex-start !important
    .justify-content-sm-end
        -ms-flex-pack end !important
        justify-content flex-end !important
    .justify-content-sm-center
        -ms-flex-pack center !important
        justify-content center !important
    .justify-content-sm-between
        -ms-flex-pack justify !important
        justify-content space-between !important
    .justify-content-sm-around
        -ms-flex-pack distribute !important
        justify-content space-around !important

@media (min-width: $grid-md)
    .justify-content-md-start
        -ms-flex-pack start !important
        justify-content flex-start !important
    .justify-content-md-end
        -ms-flex-pack end !important
        justify-content flex-end !important
    .justify-content-md-center
        -ms-flex-pack center !important
        justify-content center !important
    .justify-content-md-between
        -ms-flex-pack justify !important
        justify-content space-between !important
    .justify-content-md-around
        -ms-flex-pack distribute !important
        justify-content space-around !important

@media (min-width: $grid-lg)
    .justify-content-lg-start
        -ms-flex-pack start !important
        justify-content flex-start !important
    .justify-content-lg-end
        -ms-flex-pack end !important
        justify-content flex-end !important
    .justify-content-lg-center
        -ms-flex-pack center !important
        justify-content center !important
    .justify-content-lg-between
        -ms-flex-pack justify !important
        justify-content space-between !important
    .justify-content-lg-around
        -ms-flex-pack distribute !important
        justify-content space-around !important

@media (min-width: $grid-xl)
    .justify-content-xl-start
        -ms-flex-pack start !important
        justify-content flex-start !important
    .justify-content-xl-end
        -ms-flex-pack end !important
        justify-content flex-end !important
    .justify-content-xl-center
        -ms-flex-pack center !important
        justify-content center !important
    .justify-content-xl-between
        -ms-flex-pack justify !important
        justify-content space-between !important
    .justify-content-xl-around
        -ms-flex-pack distribute !important
        justify-content space-around !important
