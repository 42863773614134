.form-control
    display block
    width 100%
    padding 0.5rem 0.75rem
    font-size 1rem
    line-height 1.25
    color #495057
    background-color $white
    background-image none
    background-clip padding-box
    border 1px solid rgba(0, 0, 0, 0.15)
    border-radius 0.25rem
    transition border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s
    &::-ms-expand
        background-color transparent
        border 0
    &:focus
        color #495057
        background-color $white
        border-color #80bdff
        outline none
    &::-webkit-input-placeholder
        color $secondary
        opacity 1
    &:-ms-input-placeholder
        color $secondary
        opacity 1
    &::placeholder
        color $secondary
        opacity 1

.form-control:disabled, .form-control[readonly]
    background-color #e9ecef
    opacity 1

select
    &.form-control
        &:not([size]):not([multiple])
            height calc(2.25rem + 2px)
        &:focus
            &::-ms-value
                color #495057
                background-color $white

.form-control-file,
.form-control-range
    display block

.col-form-label
    padding-top calc(0.5rem - 1px * 2)
    padding-bottom calc(0.5rem - 1px * 2)
    margin-bottom 0

.col-form-label-lg
    padding-top calc(0.5rem - 1px * 2)
    padding-bottom calc(0.5rem - 1px * 2)
    font-size 1.25rem

.col-form-label-sm
    padding-top calc(0.25rem - 1px * 2)
    padding-bottom calc(0.25rem - 1px * 2)
    font-size 0.875rem

.col-form-legend
    padding-top 0.5rem
    padding-bottom 0.5rem
    margin-bottom 0
    font-size 1rem

.form-control-plaintext
    padding-top 0.5rem
    padding-bottom 0.5rem
    margin-bottom 0
    line-height 1.25
    border solid transparent
    border-width 1px 0

.form-control-plaintext.form-control-sm, .input-group-sm > .form-control-plaintext.form-control,
.input-group-sm > .form-control-plaintext.input-group-addon,
.input-group-sm > .input-group-btn > .form-control-plaintext.btn, .form-control-plaintext.form-control-lg, .input-group-lg > .form-control-plaintext.form-control,
.input-group-lg > .form-control-plaintext.input-group-addon,
.input-group-lg > .input-group-btn > .form-control-plaintext.btn
    padding-right 0
    padding-left 0

.form-control-sm, .input-group-sm > .form-control,
.input-group-sm > .input-group-addon,
.input-group-sm > .input-group-btn > .btn
    padding 0.25rem 0.5rem
    font-size 0.875rem
    line-height 1.5
    border-radius 0.2rem

select.form-control-sm:not([size]):not([multiple]), .input-group-sm > select.form-control:not([size]):not([multiple]),
.input-group-sm > select.input-group-addon:not([size]):not([multiple]),
.input-group-sm > .input-group-btn > select.btn:not([size]):not([multiple])
    height calc(1.8125rem + 2px)

.form-control-lg, .input-group-lg > .form-control,
.input-group-lg > .input-group-addon,
.input-group-lg > .input-group-btn > .btn
    padding 0.5rem 1rem
    font-size 1.25rem
    line-height 1.5
    border-radius 0.3rem

select.form-control-lg:not([size]):not([multiple]), .input-group-lg > select.form-control:not([size]):not([multiple]),
.input-group-lg > select.input-group-addon:not([size]):not([multiple]),
.input-group-lg > .input-group-btn > select.btn:not([size]):not([multiple])
    height calc(2.3125rem + 2px)

.form-group
    margin-bottom 1rem

.form-text
    display block
    margin-top 0.25rem

.form-row
    display -ms-flexbox
    display flex
    -ms-flex-wrap wrap
    flex-wrap wrap
    margin-right -5px
    margin-left -5px

.form-row > .col,
.form-row > [class*="col-"]
    padding-right 5px
    padding-left 5px

.form-check
    position relative
    display block
    margin-bottom 0.5rem
    &.disabled
        .form-check-label
            color $secondary

.form-check-label
    padding-left 1.25rem
    margin-bottom 0

.form-check-input
    position absolute
    margin-top 0.25rem
    margin-left -1.25rem
    &:only-child
        position static

.form-check-inline
    display inline-block
    .form-check-label
        vertical-align middle
    & + .form-check-inline
        margin-left 0.75rem

.invalid-feedback
    display none
    margin-top .25rem
    font-size .875rem
    color $danger

.invalid-tooltip
    position absolute
    top 100%
    z-index 5
    display none
    width 250px
    padding .5rem
    margin-top .1rem
    font-size .875rem
    line-height 1
    color $white
    background-color rgba(220, 53, 69, 0.8)
    border-radius .2rem

.was-validated .form-control:valid, .form-control.is-valid, .was-validated
.custom-select:valid,
.custom-select.is-valid
    border-color $success

.was-validated .form-control:valid:focus, .form-control.is-valid:focus, .was-validated
.custom-select:valid:focus,
.custom-select.is-valid:focus
    box-shadow 0 0 0 0.2rem rgba(40, 167, 69, 0.25)

.was-validated .form-control:valid ~ .invalid-feedback,
.was-validated .form-control:valid ~ .invalid-tooltip, .form-control.is-valid ~ .invalid-feedback,
.form-control.is-valid ~ .invalid-tooltip, .was-validated
.custom-select:valid ~ .invalid-feedback,
.was-validated
.custom-select:valid ~ .invalid-tooltip,
.custom-select.is-valid ~ .invalid-feedback,
.custom-select.is-valid ~ .invalid-tooltip
    display block

.was-validated .form-check-input:valid + .form-check-label, .form-check-input.is-valid + .form-check-label
    color $success

.was-validated .custom-control-input:valid ~ .custom-control-indicator, .custom-control-input.is-valid ~ .custom-control-indicator
    background-color rgba(40, 167, 69, 0.25)

.was-validated .custom-control-input:valid ~ .custom-control-description, .custom-control-input.is-valid ~ .custom-control-description
    color $success

.was-validated .custom-file-input:valid ~ .custom-file-control, .custom-file-input.is-valid ~ .custom-file-control
    border-color $success

.was-validated .custom-file-input:valid ~ .custom-file-control::before, .custom-file-input.is-valid ~ .custom-file-control::before
    border-color inherit

.was-validated .custom-file-input:valid:focus, .custom-file-input.is-valid:focus
    box-shadow 0 0 0 0.2rem rgba(40, 167, 69, 0.25)

.was-validated .form-control:invalid, .form-control.is-invalid, .was-validated
.custom-select:invalid,
.custom-select.is-invalid
    border-color $danger

.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus, .was-validated
.custom-select:invalid:focus,
.custom-select.is-invalid:focus
    box-shadow 0 0 0 0.2rem rgba(220, 53, 69, 0.25)

.was-validated .form-control:invalid ~ .invalid-feedback,
.was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback,
.form-control.is-invalid ~ .invalid-tooltip, .was-validated
.custom-select:invalid ~ .invalid-feedback,
.was-validated
.custom-select:invalid ~ .invalid-tooltip,
.custom-select.is-invalid ~ .invalid-feedback,
.custom-select.is-invalid ~ .invalid-tooltip
    display block

.was-validated .form-check-input:invalid + .form-check-label, .form-check-input.is-invalid + .form-check-label
    color $danger

.was-validated .custom-control-input:invalid ~ .custom-control-indicator, .custom-control-input.is-invalid ~ .custom-control-indicator
    background-color rgba(220, 53, 69, 0.25)

.was-validated .custom-control-input:invalid ~ .custom-control-description, .custom-control-input.is-invalid ~ .custom-control-description
    color $danger

.was-validated .custom-file-input:invalid ~ .custom-file-control, .custom-file-input.is-invalid ~ .custom-file-control
    border-color $danger

.was-validated .custom-file-input:invalid ~ .custom-file-control::before, .custom-file-input.is-invalid ~ .custom-file-control::before
    border-color inherit

.was-validated .custom-file-input:invalid:focus, .custom-file-input.is-invalid:focus
    box-shadow 0 0 0 0.2rem rgba(220, 53, 69, 0.25)

.form-inline
    display -ms-flexbox
    display flex
    -ms-flex-flow row wrap
    flex-flow row wrap
    -ms-flex-align center
    align-items center
    .form-check
        width 100%

@media (min-width: $grid-sm)
    .form-inline
        label
            display -ms-flexbox
            display flex
            -ms-flex-align center
            align-items center
            -ms-flex-pack center
            justify-content center
            margin-bottom 0
        .form-group
            display -ms-flexbox
            display flex
            -ms-flex 0 0 auto
            flex 0 0 auto
            -ms-flex-flow row wrap
            flex-flow row wrap
            -ms-flex-align center
            align-items center
            margin-bottom 0
        .form-control
            display inline-block
            width auto
            vertical-align middle
        .form-control-plaintext
            display inline-block
        .input-group
            width auto
        .form-control-label
            margin-bottom 0
            vertical-align middle
        .form-check
            display -ms-flexbox
            display flex
            -ms-flex-align center
            align-items center
            -ms-flex-pack center
            justify-content center
            width auto
            margin-top 0
            margin-bottom 0
        .form-check-label
            padding-left 0
        .form-check-input
            position relative
            margin-top 0
            margin-right 0.25rem
            margin-left 0
        .custom-control
            display -ms-flexbox
            display flex
            -ms-flex-align center
            align-items center
            -ms-flex-pack center
            justify-content center
            padding-left 0
        .custom-control-indicator
            position static
            display inline-block
            margin-right 0.25rem
            vertical-align text-bottom
        .has-feedback
            .form-control-feedback
                top 0