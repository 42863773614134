.input-group
    position relative
    display -ms-flexbox
    display flex
    width 100%
    .form-control
        position relative
        z-index 2
        -ms-flex 1 1 auto
        flex 1 1 auto
        width 1%
        margin-bottom 0

.input-group .form-control:focus, .input-group .form-control:active, .input-group .form-control:hover
    z-index 3

.input-group-addon,
.input-group-btn,
.input-group .form-control
    display -ms-flexbox
    display flex
    -ms-flex-align center
    align-items center

.input-group-addon:not(:first-child):not(:last-child),
.input-group-btn:not(:first-child):not(:last-child),
.input-group .form-control:not(:first-child):not(:last-child)
    border-radius 0

.input-group-addon,
.input-group-btn
    white-space nowrap
    vertical-align middle

.input-group-addon
    padding 0.5rem 0.75rem
    margin-bottom 0
    font-size 1rem
    font-weight $font-weight-normal
    line-height 1.25
    color #495057
    text-align center
    background-color #e9ecef
    border 1px solid rgba(0, 0, 0, 0.15)
    border-radius 0.25rem
    &:not(:last-child)
        border-right 0

.input-group-addon.form-control-sm,
.input-group-sm > .input-group-addon,
.input-group-sm > .input-group-btn > .input-group-addon.btn
    padding 0.25rem 0.5rem
    font-size 0.875rem
    border-radius 0.2rem

.input-group-addon.form-control-lg,
.input-group-lg > .input-group-addon,
.input-group-lg > .input-group-btn > .input-group-addon.btn
    padding 0.5rem 1rem
    font-size 1.25rem
    border-radius 0.3rem

.input-group-addon input[type="radio"],
.input-group-addon input[type="checkbox"]
    margin-top 0

.input-group .form-control:not(:last-child),
.input-group-addon:not(:last-child),
.input-group-btn:not(:last-child) > .btn,
.input-group-btn:not(:last-child) > .btn-group > .btn,
.input-group-btn:not(:last-child) > .dropdown-toggle,
.input-group-btn:not(:first-child) > .btn:not(:last-child):not(.dropdown-toggle),
.input-group-btn:not(:first-child) > .btn-group:not(:last-child) > .btn
    border-top-right-radius 0
    border-bottom-right-radius 0

.input-group .form-control:not(:first-child),
.input-group-addon:not(:first-child),
.input-group-btn:not(:first-child) > .btn,
.input-group-btn:not(:first-child) > .btn-group > .btn,
.input-group-btn:not(:first-child) > .dropdown-toggle,
.input-group-btn:not(:last-child) > .btn:not(:first-child),
.input-group-btn:not(:last-child) > .btn-group:not(:first-child) > .btn
    border-top-left-radius 0
    border-bottom-left-radius 0

.form-control
    & + .input-group-addon
        &:not(:first-child)
            border-left 0

.input-group-btn
    position relative
    font-size 0
    white-space nowrap
    & > .btn
        position relative
        & + .btn
            margin-left -1px

.input-group-btn > .btn:focus, .input-group-btn > .btn:active, .input-group-btn > .btn:hover
    z-index 3

.input-group-btn:not(:last-child) > .btn,
.input-group-btn:not(:last-child) > .btn-group
    margin-right -1px

.input-group-btn:not(:first-child) > .btn,
.input-group-btn:not(:first-child) > .btn-group
    z-index 2
    margin-left -1px

.input-group-btn:not(:first-child) > .btn:focus, .input-group-btn:not(:first-child) > .btn:active, .input-group-btn:not(:first-child) > .btn:hover,
.input-group-btn:not(:first-child) > .btn-group:focus,
.input-group-btn:not(:first-child) > .btn-group:active,
.input-group-btn:not(:first-child) > .btn-group:hover
    z-index 3