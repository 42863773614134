.border
    border 1px solid #e9ecef !important

.border-0
    border 0 !important

.border-top-0
    border-top 0 !important

.border-right-0
    border-right 0 !important

.border-bottom-0
    border-bottom 0 !important

.border-left-0
    border-left 0 !important

.border-primary
    border-color $primary !important

.border-secondary
    border-color $secondary !important

.border-success
    border-color $success !important

.border-info
    border-color $info !important

.border-warning
    border-color $warning !important

.border-danger
    border-color $danger !important

.border-light
    border-color $light !important

.border-dark
    border-color $dark !important

.border-white
    border-color $white !important