.nav
    display -ms-flexbox
    display flex
    -ms-flex-wrap wrap
    flex-wrap wrap
    padding-left 0
    margin-bottom 0
    list-style none

.nav-link
    display block
    padding 0.5rem 1rem
    &.disabled
        color $secondary

.nav-link:focus, .nav-link:hover
    text-decoration none

.nav-tabs
    border-bottom 1px solid #ddd
    .nav-item
        margin-bottom -1px
    .nav-link
        border 1px solid transparent
        border-top-left-radius 0.25rem
        border-top-right-radius 0.25rem
        &.disabled
            color $secondary
            background-color transparent
            border-color transparent
    .dropdown-menu
        margin-top -1px
        border-top-left-radius 0
        border-top-right-radius 0

.nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover
    border-color #e9ecef #e9ecef #ddd

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link
    color #495057
    background-color $white
    border-color #ddd #ddd $white

.nav-pills
    .nav-link
        border-radius 0.25rem

.nav-pills .nav-link.active,
.show > .nav-pills .nav-link
    color $white
    background-color $primary

.nav-fill
    .nav-item
        -ms-flex 1 1 auto
        flex 1 1 auto
        text-align center

.nav-justified
    .nav-item
        -ms-flex-preferred-size 0
        flex-basis 0
        -ms-flex-positive 1
        flex-grow 1
        text-align center

.tab-content
    & > .tab-pane
        display none
    & > .active
        display block

.navbar
    position relative
    display -ms-flexbox
    display flex
    -ms-flex-wrap wrap
    flex-wrap wrap
    -ms-flex-align center
    align-items center
    -ms-flex-pack justify
    justify-content space-between
    padding 0.5rem 1rem

.navbar > .container,
.navbar > .container-fluid
    display -ms-flexbox
    display flex
    -ms-flex-wrap wrap
    flex-wrap wrap
    -ms-flex-align center
    align-items center
    -ms-flex-pack justify
    justify-content space-between

.navbar-brand
    display inline-block
    padding-top 0.3125rem
    padding-bottom 0.3125rem
    margin-right 1rem
    font-size 1.25rem
    line-height inherit
    white-space nowrap

.navbar-brand:focus, .navbar-brand:hover
    text-decoration none

.navbar-nav
    display -ms-flexbox
    display flex
    -ms-flex-direction column
    flex-direction column
    padding-left 0
    margin-bottom 0
    list-style none
    .nav-link
        padding-right 0
        padding-left 0
    .dropdown-menu
        position static
        float none

.navbar-text
    display inline-block
    padding-top 0.5rem
    padding-bottom 0.5rem

.navbar-collapse
    -ms-flex-preferred-size 100%
    flex-basis 100%
    -ms-flex-align center
    align-items center

.navbar-toggler
    padding 0.25rem 0.75rem
    font-size 1.25rem
    line-height 1
    background transparent
    border 1px solid transparent
    border-radius 0.25rem

.navbar-toggler:focus, .navbar-toggler:hover
    text-decoration none

.navbar-toggler-icon
    display inline-block
    width 1.5em
    height 1.5em
    vertical-align middle
    content ""
    background no-repeat center center
    background-size 100% 100%


@media (max-width: 575px)
    .navbar-expand-sm > .container,.navbar-expand-sm > .container-fluid
        padding-right 0
        padding-left 0

@media (min-width: $grid-sm)
    .navbar-expand-sm
        -ms-flex-direction row
        flex-direction row
        -ms-flex-wrap nowrap
        flex-wrap nowrap
        -ms-flex-pack start
        justify-content flex-start
        .navbar-nav
            -ms-flex-direction row
            flex-direction row
            .dropdown-menu
                position absolute
            .dropdown-menu-right
                right 0
                left auto
            .nav-link
                padding-right .5rem
                padding-left .5rem
        .navbar-collapse
            display -ms-flexbox !important
            display flex !important
        .navbar-toggler
            display none
    .navbar-expand-sm > .container, .navbar-expand-sm > .container-fluid
        -ms-flex-wrap nowrap
        flex-wrap nowrap


@media (max-width: 767px)   
    .navbar-expand-md > .container, .navbar-expand-md > .container-fluid
        padding-right 0
        padding-left 0

@media (min-width: $grid-md)
    .navbar-expand-md
        -ms-flex-direction row
        flex-direction row
        -ms-flex-wrap nowrap
        flex-wrap nowrap
        -ms-flex-pack start
        justify-content flex-start
        .navbar-nav
            -ms-flex-direction row
            flex-direction row
            .dropdown-menu
                position absolute
            .dropdown-menu-right
                right 0
                left auto
            .nav-link
                padding-right .5rem
                padding-left .5rem
        .navbar-collapse
            display -ms-flexbox !important
            display flex !important
        .navbar-toggler
            display none
    .navbar-expand-md > .container, .navbar-expand-md > .container-fluid
        -ms-flex-wrap nowrap
        flex-wrap nowrap


@media (max-width: 991px)
    .navbar-expand-lg > .container, .navbar-expand-lg > .container-fluid
        padding-right 0
        padding-left 0

@media (min-width: $grid-lg)
    .navbar-expand-lg
        -ms-flex-direction row
        flex-direction row
        -ms-flex-wrap nowrap
        flex-wrap nowrap
        -ms-flex-pack start
        justify-content flex-start
        .navbar-nav
            -ms-flex-direction row
            flex-direction row
            .dropdown-menu
                position absolute
            .dropdown-menu-right
                right 0
                left auto
            .nav-link
                padding-right .5rem
                padding-left .5rem
        .navbar-collapse
            display -ms-flexbox !important
            display flex !important
        .navbar-toggler
            display none
    .navbar-expand-lg > .container,.navbar-expand-lg > .container-fluid
        -ms-flex-wrap nowrap
        flex-wrap nowrap


@media (max-width: 1199px)
    .navbar-expand-xl > .container,.navbar-expand-xl > .container-fluid
        padding-right 0
        padding-left 0

@media (min-width: $grid-xl)
    .navbar-expand-xl
        -ms-flex-direction row
        flex-direction row
        -ms-flex-wrap nowrap
        flex-wrap nowrap
        -ms-flex-pack start
        justify-content flex-start
        .navbar-nav
            -ms-flex-direction row
            flex-direction row
            .dropdown-menu
                position absolute
            .dropdown-menu-right
                right 0
                left auto
            .nav-link
                padding-right .5rem
                padding-left .5rem
        .navbar-collapse
            display -ms-flexbox !important
            display flex !important
        .navbar-toggler
            display none
    .navbar-expand-xl > .container, .navbar-expand-xl > .container-fluid
        -ms-flex-wrap nowrap
        flex-wrap nowrap

.navbar-expand
    -ms-flex-direction row
    flex-direction row
    -ms-flex-wrap nowrap
    flex-wrap nowrap
    -ms-flex-pack start
    justify-content flex-start
    .navbar-nav
        -ms-flex-direction row
        flex-direction row
        .dropdown-menu
            position absolute
        .dropdown-menu-right
            right 0
            left auto
        .nav-link
            padding-right .5rem
            padding-left .5rem
    .navbar-collapse
        display -ms-flexbox !important
        display flex !important
    .navbar-toggler
        display none

.navbar-expand > .container,
.navbar-expand > .container-fluid
    padding-right 0
    padding-left 0
    -ms-flex-wrap nowrap
    flex-wrap nowrap

.navbar-light
    .navbar-brand
        color rgba(0, 0, 0, 0.9)
    .navbar-nav
        .nav-link
            color rgba(0, 0, 0, 0.5)
            &.disabled
                color rgba(0, 0, 0, 0.3)
    .navbar-toggler
        color rgba(0, 0, 0, 0.5)
        border-color rgba(0, 0, 0, 0.1)
    .navbar-toggler-icon
        background-image url("data:image/svg+xml charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http //www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E")
    .navbar-text
        color rgba(0, 0, 0, 0.5)

.navbar-light .navbar-brand:focus, .navbar-light .navbar-brand:hover
    color rgba(0, 0, 0, 0.9)

.navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link:hover
    color rgba(0, 0, 0, 0.7)

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active
    color rgba(0, 0, 0, 0.9)

.navbar-dark
    .navbar-brand
        color white
    .navbar-nav
        .nav-link
            color rgba(255, 255, 255, 0.5)
            &.disabled
                color rgba(255, 255, 255, 0.25)
    .navbar-toggler
        color rgba(255, 255, 255, 0.5)
        border-color rgba(255, 255, 255, 0.1)
    .navbar-toggler-icon
        background-image url("data:image/svg+xml charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http //www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E")
    .navbar-text
        color rgba(255, 255, 255, 0.5)

.navbar-dark .navbar-brand:focus, .navbar-dark .navbar-brand:hover
    color white

.navbar-dark .navbar-nav .nav-link:focus, .navbar-dark .navbar-nav .nav-link:hover
    color rgba(255, 255, 255, 0.75)

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active
    color white