.float-left
    float left !important

.float-right
    float right !important

.float-none
    float none !important

@media (min-width: $grid-sm)
    .float-sm-left
        float left !important
    .float-sm-right
        float right !important
    .float-sm-none
        float none !important

@media (min-width: $grid-md)
    .float-md-left
        float left !important
    .float-md-right
        float right !important
    .float-md-none
        float none !important

@media (min-width: $grid-lg)
    .float-lg-left
        float left !important
    .float-lg-right
        float right !important
    .float-lg-none
        float none !important

@media (min-width: $grid-xl)
    .float-xl-left
        float left !important
    .float-xl-right
        float right !important
    .float-xl-none
        float none !important