.custom-control
    position relative
    display -ms-inline-flexbox
    display inline-flex
    min-height 1.5rem
    padding-left 1.5rem
    margin-right 1rem

.custom-control-input
    position absolute
    z-index -1
    opacity 0
    &:checked
        & ~ .custom-control-indicator
            color $white
            background-color $primary
    &:focus
        & ~ .custom-control-indicator
            box-shadow 0 0 0 1px $white, 0 0 0 3px $primary
    &:active
        & ~ .custom-control-indicator
            color $white
            background-color #b3d7ff
    &:disabled
        & ~ .custom-control-indicator
            background-color #e9ecef
        & ~ .custom-control-description
            color $secondary

.custom-control-indicator
    position absolute
    top 0.25rem
    left 0
    display block
    width 1rem
    height 1rem
    pointer-events none
    -webkit-user-select none
    -moz-user-select none
    -ms-user-select none
    user-select none
    background-color #ddd
    background-repeat no-repeat
    background-position center center
    background-size 50% 50%

.custom-checkbox
    .custom-control-indicator
        border-radius 0.25rem
    .custom-control-input
        &:checked
            & ~ .custom-control-indicator
                background-image url("data:image/svg+xml charset=utf8,%3Csvg xmlns='http //www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E")
        &:indeterminate
            & ~ .custom-control-indicator
                background-color $primary
                background-image url("data:image/svg+xml charset=utf8,%3Csvg xmlns='http //www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='%23fff' d='M0 2h4'/%3E%3C/svg%3E")

.custom-radio
    .custom-control-indicator
        border-radius 50%
    .custom-control-input
        &:checked
            & ~ .custom-control-indicator
                background-image url("data:image/svg+xml charset=utf8,%3Csvg xmlns='http //www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E")

.custom-controls-stacked
    display -ms-flexbox
    display flex
    -ms-flex-direction column
    flex-direction column
    .custom-control
        margin-bottom 0.25rem
        & + .custom-control
            margin-left 0

.custom-select
    display inline-block
    max-width 100%
    height calc(2.25rem + 2px)
    padding 0.375rem 1.75rem 0.375rem 0.75rem
    line-height 1.25
    color #495057
    vertical-align middle
    background $white url("data:image/svg+xml charset=utf8,%3Csvg xmlns='http //www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23333' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right 0.75rem center
    background-size 8px 10px
    border 1px solid rgba(0, 0, 0, 0.15)
    border-radius 0.25rem
    -webkit-appearance none
    -moz-appearance none
    appearance none
    &:focus
        border-color #80bdff
        outline none
        &::-ms-value
            color #495057
            background-color $white
    &:disabled
        color $secondary
        background-color #e9ecef
    &::-ms-expand
        opacity 0

.custom-select-sm
    height calc(1.8125rem + 2px)
    padding-top 0.375rem
    padding-bottom 0.375rem
    font-size 75%

.custom-file
    position relative
    display inline-block
    max-width 100%
    height 2.5rem
    margin-bottom 0

.custom-file-input
    min-width 14rem
    max-width 100%
    height 2.5rem
    margin 0
    opacity 0

.custom-file-control
    position absolute
    top 0
    right 0
    left 0
    z-index 5
    height 2.5rem
    padding 0.5rem 1rem
    line-height 1.5
    color #495057
    pointer-events none
    -webkit-user-select none
    -moz-user-select none
    -ms-user-select none
    user-select none
    background-color $white
    border 1px solid rgba(0, 0, 0, 0.15)
    border-radius 0.25rem
    &:lang(en):empty
        &::after
            content "Choose file..."
    &::before
        position absolute
        top -1px
        right -1px
        bottom -1px
        z-index 6
        display block
        height 2.5rem
        padding 0.5rem 1rem
        line-height 1.5
        color #495057
        background-color #e9ecef
        border 1px solid rgba(0, 0, 0, 0.15)
        border-radius 0 0.25rem 0.25rem 0
    &:lang(en)::before
        content "Browse"