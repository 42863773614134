.align-self-auto
    -ms-flex-item-align auto !important
    align-self auto !important

.align-self-start
    -ms-flex-item-align start !important
    align-self flex-start !important

.align-self-end
    -ms-flex-item-align end !important
    align-self flex-end !important

.align-self-center
    -ms-flex-item-align center !important
    align-self center !important

.align-self-baseline
    -ms-flex-item-align baseline !important
    align-self baseline !important

.align-self-stretch
    -ms-flex-item-align stretch !important
    align-self stretch !important
	
@media (min-width: $grid-sm)
    .align-self-sm-auto
        -ms-flex-item-align auto !important
        align-self auto !important
    .align-self-sm-start
        -ms-flex-item-align start !important
        align-self flex-start !important
    .align-self-sm-end
        -ms-flex-item-align end !important
        align-self flex-end !important
    .align-self-sm-center
        -ms-flex-item-align center !important
        align-self center !important
    .align-self-sm-baseline
        -ms-flex-item-align baseline !important
        align-self baseline !important
    .align-self-sm-stretch
        -ms-flex-item-align stretch !important
        align-self stretch !important
		
@media (min-width: $grid-md)
    .align-self-md-auto
        -ms-flex-item-align auto !important
        align-self auto !important
    .align-self-md-start
        -ms-flex-item-align start !important
        align-self flex-start !important
    .align-self-md-end
        -ms-flex-item-align end !important
        align-self flex-end !important
    .align-self-md-center
        -ms-flex-item-align center !important
        align-self center !important
    .align-self-md-baseline
        -ms-flex-item-align baseline !important
        align-self baseline !important
    .align-self-md-stretch
        -ms-flex-item-align stretch !important
        align-self stretch !important

@media (min-width: $grid-lg)
    .align-self-lg-auto
        -ms-flex-item-align auto !important
        align-self auto !important
    .align-self-lg-start
        -ms-flex-item-align start !important
        align-self flex-start !important
    .align-self-lg-end
        -ms-flex-item-align end !important
        align-self flex-end !important
    .align-self-lg-center
        -ms-flex-item-align center !important
        align-self center !important
    .align-self-lg-baseline
        -ms-flex-item-align baseline !important
        align-self baseline !important
    .align-self-lg-stretch
        -ms-flex-item-align stretch !important
        align-self stretch !important

@media (min-width: $grid-xl)
    .align-self-xl-auto
        -ms-flex-item-align auto !important
        align-self auto !important
    .align-self-xl-start
        -ms-flex-item-align start !important
        align-self flex-start !important
    .align-self-xl-end
        -ms-flex-item-align end !important
        align-self flex-end !important
    .align-self-xl-center
        -ms-flex-item-align center !important
        align-self center !important
    .align-self-xl-baseline
        -ms-flex-item-align baseline !important
        align-self baseline !important
    .align-self-xl-stretch
        -ms-flex-item-align stretch !important
        align-self stretch !important