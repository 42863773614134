.align-items-start
    -ms-flex-align start !important
    align-items flex-start !important

.align-items-end
    -ms-flex-align end !important
    align-items flex-end !important

.align-items-center
    -ms-flex-align center !important
    align-items center !important

.align-items-baseline
    -ms-flex-align baseline !important
    align-items baseline !important

.align-items-stretch
    -ms-flex-align stretch !important
    align-items stretch !important
	
@media (min-width: $grid-sm)
    .align-items-sm-start
        -ms-flex-align start !important
        align-items flex-start !important
    .align-items-sm-end
        -ms-flex-align end !important
        align-items flex-end !important
    .align-items-sm-center
        -ms-flex-align center !important
        align-items center !important
    .align-items-sm-baseline
        -ms-flex-align baseline !important
        align-items baseline !important
    .align-items-sm-stretch
        -ms-flex-align stretch !important
        align-items stretch !important
 
@media (min-width: $grid-md)
    .align-items-md-start
        -ms-flex-align start !important
        align-items flex-start !important
    .align-items-md-end
        -ms-flex-align end !important
        align-items flex-end !important
    .align-items-md-center
        -ms-flex-align center !important
        align-items center !important
    .align-items-md-baseline
        -ms-flex-align baseline !important
        align-items baseline !important
    .align-items-md-stretch
        -ms-flex-align stretch !important
        align-items stretch !important

@media (min-width: $grid-lg)
    .align-items-lg-start
        -ms-flex-align start !important
        align-items flex-start !important
    .align-items-lg-end
        -ms-flex-align end !important
        align-items flex-end !important
    .align-items-lg-center
        -ms-flex-align center !important
        align-items center !important
    .align-items-lg-baseline
        -ms-flex-align baseline !important
        align-items baseline !important
    .align-items-lg-stretch
        -ms-flex-align stretch !important
        align-items stretch !important

@media (min-width: $grid-xl)
    .align-items-xl-start
        -ms-flex-align start !important
        align-items flex-start !important
    .align-items-xl-end
        -ms-flex-align end !important
        align-items flex-end !important
    .align-items-xl-center
        -ms-flex-align center !important
        align-items center !important
    .align-items-xl-baseline
        -ms-flex-align baseline !important
        align-items baseline !important
    .align-items-xl-stretch
        -ms-flex-align stretch !important
        align-items stretch !important