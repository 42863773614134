.align-baseline
    vertical-align baseline !important

.align-top
    vertical-align top !important

.align-middle
    vertical-align middle !important

.align-bottom
    vertical-align bottom !important

.align-text-bottom
    vertical-align text-bottom !important

.align-text-top
    vertical-align text-top !important