.tooltip
    position absolute
    z-index 1070
    display block
    margin 0
    font-family $font-family-base
    font-style normal
    font-weight $font-weight-normal
    line-height 1.5
    text-align left
    text-align start
    text-decoration none
    text-shadow none
    text-transform none
    letter-spacing normal
    word-break normal
    word-spacing normal
    white-space normal
    line-break auto
    font-size 0.875rem
    word-wrap break-word
    opacity 0
    &.show
        opacity 0.9
    .arrow
        position absolute
        display block
        width 5px
        height 5px
        &::before
            position absolute
            border-color transparent
            border-style solid

.tooltip.bs-tooltip-top, .tooltip.bs-tooltip-auto[x-placement^="top"]
    padding 5px 0

.tooltip.bs-tooltip-top .arrow, .tooltip.bs-tooltip-auto[x-placement^="top"] .arrow
    bottom 0

.tooltip.bs-tooltip-top .arrow::before, .tooltip.bs-tooltip-auto[x-placement^="top"] .arrow::before
    margin-left -3px
    content ""
    border-width 5px 5px 0
    border-top-color $black

.tooltip.bs-tooltip-right, .tooltip.bs-tooltip-auto[x-placement^="right"]
    padding 0 5px

.tooltip.bs-tooltip-right .arrow, .tooltip.bs-tooltip-auto[x-placement^="right"] .arrow
    left 0

.tooltip.bs-tooltip-right .arrow::before, .tooltip.bs-tooltip-auto[x-placement^="right"] .arrow::before
    margin-top -3px
    content ""
    border-width 5px 5px 5px 0
    border-right-color $black

.tooltip.bs-tooltip-bottom, .tooltip.bs-tooltip-auto[x-placement^="bottom"]
    padding 5px 0

.tooltip.bs-tooltip-bottom .arrow, .tooltip.bs-tooltip-auto[x-placement^="bottom"] .arrow
    top 0

.tooltip.bs-tooltip-bottom .arrow::before, .tooltip.bs-tooltip-auto[x-placement^="bottom"] .arrow::before
    margin-left -3px
    content ""
    border-width 0 5px 5px
    border-bottom-color $black

.tooltip.bs-tooltip-left, .tooltip.bs-tooltip-auto[x-placement^="left"]
    padding 0 5px

.tooltip.bs-tooltip-left .arrow, .tooltip.bs-tooltip-auto[x-placement^="left"] .arrow
    right 0

.tooltip.bs-tooltip-left .arrow::before, .tooltip.bs-tooltip-auto[x-placement^="left"] .arrow::before
    right 0
    margin-top -3px
    content ""
    border-width 5px 0 5px 5px
    border-left-color $black

.tooltip-inner
    max-width 200px
    padding 3px 8px
    color $white
    text-align center
    background-color $black
    border-radius 0.25rem