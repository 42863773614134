h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6
    margin-bottom 0.5rem
    font-family inherit
    font-weight 500
    line-height 1.1
    color inherit

h1, .h1
    font-size $h1-font-size

h2, .h2
    font-size $h2-font-size

h3, .h3
    font-size $h3-font-size

h4, .h4
    font-size $h4-font-size

h5, .h5
    font-size $h5-font-size

h6, .h6
    font-size $h6-font-size