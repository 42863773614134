@import "bootstrap4"
rupture.scale = 0 575px 767px 991px 1200px 1600px
rupture.anti-overlap = 1px

/*Variables-------*/
$black = hsl(0, 0%, 0%)
$white = hsl(0, 100%, 100%)
$orange = hsl(246, 52%, 28%)
$orange-transparent = hsla(246, 52%, 28%, 0.95)
$overlay-black = hsla(0, 0%, 0%, 0.6)
$blue = #007bff
$medium-grey = #4f565d
$dark-grey = hsla(210, 6%, 12%, 1)
$off-white = #F9F2EF
$yellow = hsl(48, 73%, 76%)
$yellow-transparent = hsla(48, 73%, 76%, 0.8)

$font-stack = 'Open Sans', helvetica, arial, sans-serif
$font-stack-headers = 'Montserrat', helvetica, arial, sans-serif

/*Mixins---*/
background-img($y-position, $x-position)
    background-repeat no-repeat
    background-position $y-position $x-position
    background-size cover
    
transition-all()
    transition all .3s ease-in-out
    
headline($color, $size, $weight, $line)
    color $color
    font-family $font-stack-headers
    font-size $size
    font-weight $weight
    line-height $line

objectfit($obj-width, $obj-height, $obj-pos)
    object-fit cover
    object-position $obj-pos
    height $obj-height
    width $obj-width
    
/*Fonts Icons--*/
@font-face
    font-family 'icomoon'
    src url('fonts/icomoon.eot?am0fqf')
    src url('fonts/icomoon.eot?am0fqf#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?am0fqf') format('truetype'),
    url('fonts/icomoon.woff?am0fqf') format('woff'),
    url('fonts/icomoon.svg?am0fqf#icomoon') format('svg')
    font-weight normal
    font-style normal

[class^="icon-"], [class*=" icon-"]
    font-family 'icomoon' !important
    speak none
    font-style normal
    font-weight normal
    font-variant normal
    text-transform none
    line-height 1
    -webkit-font-smoothing antialiased
    -moz-osx-font-smoothing grayscale

.icon-hexagon-v
    &:before
        content "\e900"
 
.headroom
    background-color transparent
    transition all 200ms linear
    z-index 1
    position fixed
    width 100%

.headroom--pinned
    transform translateY(0%)

.headroom--unpinned 
    transform translateY(-100%)
    
.headroom--top
    background-color transparent
    
.headroom--not-top.headroom--pinned
    .navbar-toggler
        background-color $orange-transparent
    
/*Site Styles---------------------------------*/
/*Stops Flash of Unstyled Text (requires google fonts loader)*/
p, li, ol, a, h1, h2, h3, h4, h5, h6, dd, dl, dt, td, strong, address, label, button, blockquote, caption, figcaption, legend, input::placeholder
    opacity 0
    transition all .2s ease-in-out
    
.wf-active
    p, li, ol, a, h1, h2, h3, h4, h5, h6, dd, dl, dt, td, strong, address, label, button, blockquote, caption, figcaption, legend, input::placeholder
        opacity 1 !important
        transition all .2s ease-in-out

body
    color $white
    line-height 1.8
    height auto !important
h1, h2, h3
    font-family $font-stack-headers
    font-weight 700
p, ul
    font-family $font-stack
    font-weight 300
main
    padding 90px 0
    section 
        padding 30px 0
        
a
    transition-all()
    &:hover
        text-decoration none
        
img
    max-width 100%
        
section 
    padding 80px
    +below(767px)
        padding 80px 0
    &.info
        +below(767px)
            padding-top 0
    &.work
        padding 80px 0 0 0
        .container-fluid, .container
            +above(1199px)
                padding-left 100px
                padding-right 100px
.mr-auto
    margin-right auto !important

.cover-img
    overflow hidden
    max-height 450px
    padding 0
    display flex
    align-items center
    justify-content center
    flex-direction column

.info-text
    display flex
    align-items center
    justify-content center
    flex-direction column
    .aligner 
        width 80%
    .align-item
        padding 15px
        width 80%
        &.transparent
            background-color hsla(360, 100%, 100%, 0.85)

.navbar-collapse
    flex-direction column
    padding-right 12px
    margin-top 20px
.top-nav
    margin-left auto
    #menu-top-nav, #menu-top-nav-1
        display flex
        flex-direction row
        margin-left auto
        list-style none
        padding-left 0
        margin-bottom 10px
        li 
            a 
                color hsla(360, 100%, 100%, 0.5)
                padding .5rem
                &:hover, &:focus
                    color hsla(360, 100%, 100%, 1)
.navbar-collapse
    align-items flex-end
.top-area
    .widgitable-area
        display flex
        flex-direction row
.bottom-area
    display none
    
.btn-portfolio, .wpcf7-submit
    border-radius 0
    border 5px solid $yellow-transparent
    color $yellow-transparent
    font-family $font-stack-headers
    font-size responsive 1rem 1.13rem
    font-range 768px 992px
    font-weight 700
    text-transform uppercase
    &:hover, &:focus
        background-color $yellow
        color $orange
.btn-portfolio
  +below(767px)
    margin-bottom 90px
.main-img
    background-color $black
    margin-bottom 50px
    display block
    position relative
    z-index 10
        img 
            transition-all()
    &:hover, &:focus
        img
            opacity .5
            transition-all()
        .link-title
            h2
                margin-top 0
                opacity 1 !important
                transition-all()
.project
  .description
    ul
      margin-left 15px
      padding-left 0
  .link-title
    position absolute
    top 0
    height 100%
    width 100%
    display flex
    justify-content center
    align-items center
    h2
      margin-top 60px
      border 10px solid $yellow
      border-radius 0
      font-size responsive 1.13rem 2.75rem
      font-range 768px 992px
      opacity 0 !important
      padding 15px
      transition-all()
        
    
/*Homepage-------------------------------*/
.hex-holder
    display flex
    height 85vh
    justify-content center
    align-items center
    +between(320px, 575px)
        transform scale(.7) !important
    +below(320px)
        transform scale(.65) !important

.home
    .down-arrow
        &:hover, &:focus
            margin-top -16px
            transition-all()
    .contact
        +below(1024px)
            background-img(top, center)
            background-image url('../img/main-bg.jpg')
    +below(1024px)
        background-attachment scroll
        background-image none !important
        background-color #000
.home, .secondary, .error404, #error-page, .single-projects
    background-img(top, center)
    background-image url('../img/main-bg.jpg')
    background-attachment fixed
    .intro
        text-align center
    .jumbotron
        background-color transparent
        +below(1024px)
            background-img(top, center)
            background-image url('../img/main-bg.jpg')
        height 100vh
        border-radius 0
        margin-bottom 0
    .callout
        background-color #f1efef
section.callout
    padding 0
    &.full-bg
        background-image url('http://via.placeholder.com/1920x650')
        background-position center center
        background-size cover
        background-repeat no-repeat
        overflow hidden
        height 600px
        position relative
        .info-text
            height 600px
.main-callout
    height 100%
    width 100%
    display flex
    align-items center
    flex-direction column
    justify-content center
    h1
        color $off-white
        font-size responsive 8rem 10rem
        font-range 575px 992px
        font-weight 700
        line-height 0.8
        text-transform uppercase
        position absolute
        margin-top -25px
        span  
            font-size responsive 2.58rem 3.12rem
            font-range 575px 992px
            position absolute
            margin-top 8px
            margin-left 6px
    p 
        text-align center
        font-size 1.5rem
.multi-callout
    .multi-callout-intro
        text-align center
        margin-bottom 2rem

    
.grad-radial
    /*background radial-gradient(ellipse at center, rgba(0,0,0,0) 0%,rgba(95,38,15,0) 42%,rgba(226,90,36,1) 100%)--*/
    background radial-gradient(ellipse at center, rgba(0,0,0,0) 0%,rgba(42, 34, 109,0) 42%,rgba(42, 34, 109,1) 100%)
.info
    svg 
        position relative
        left 9px
.info, .work, .project
    /*background: linear-gradient(to right, rgba(226,90,36,.8) 0%,rgba(226,90,36,.8) 33%,rgba(226,90,36,0) 100%)*/
    background: linear-gradient(to right, rgba(42, 34, 109,.9) 0%,rgba(42, 34, 109,.8) 33%,rgba(42, 34, 109,0) 100%)
.info, .work, .project, .contact
    h2, h3
        color $yellow
        text-transform uppercase
    h2
        font-size responsive 2.69rem 4.38rem
        font-range 768px 1199px
    h3
        font-size responsive 1.5rem 2.13rem
    ul, p
        margin-top 1rem
        margin-bottom 2.5rem
.info, .work, .contact
    ul 
        padding-left 16px
        list-style none
        li 
            font-family $font-stack
            &:before
                content "\e900"
                font-size .8rem
                color $yellow
                margin 0 5px 0 -15px
                font-family 'icomoon' !important
                speak none
                font-style normal
                font-weight normal
                font-variant normal
                text-transform none
                line-height 1
                -webkit-font-smoothing antialiased
                -moz-osx-font-smoothing grayscale
            .list-text
                text-indent 15px
    .portrait
        stroke $yellow
        stroke-width 20px
    .no-clip
        overflow visible
.work
    .hex-container
        display flex
        justify-content center
        align-items center
    .container-fluid.hex-area
        +above(1199px)
            padding-left 15px
            padding-right 15px
    .description
        margin-top 15px
    .no-clip
        overflow visible
    .hex-callout 
        stroke #efde97
        stroke-width 20px
    .hex-grid
        min-height 600px
        position relative
        width 1340px
        margin 100px auto 100px auto
        +between(992px, 1400px)
            width 820px
            min-height 870px
        +between(575px, 992px)
            width 490px
            min-height 490px
        +between(465px, 575px)
            width 330px
            min-height 330px
        +between(320px, 465px)
            width 330px
            min-height 320px
            margin 0 auto 100px auto
        +below(320px)
            width 100%
            min-height 320px
            margin 0 auto 50px -15px
            transform scale(.8)
        .hex-box
            .hex-link
                &:hover, &:focus
                    h3
                        color $orange
                        text-shadow none
                    .hex-callout 
                        stroke $yellow
                        fill $yellow
                        transition-all() 
            width 300px
            position absolute
            transition-all()
            .overlay
                background-color $overlay-black
                width 100%
            h3
                color $yellow
                line-height 1.3
                font-size 1.7rem
                +between(575px, 992px)
                    font-size 1rem
                +below(575px)
                    font-size .7rem
                position absolute
                left 0
                text-align center
                top 39%
                width 100%
                margin-left 0
                text-shadow 1px 1px 0 $black
                +above(1199px)
                    width 100%
                    top 39%
                    bottom 30px
                    text-align center
                    left 0
            +between(820px, 1400px)
                width 300px
            +between(575px, 992px)
                width 180px
            +below(575px)
                width 120px
            &.hex-1
                top 150px
                left 0
                +between(992px, 1400px)
                    top 150px
                +between(575px, 992px)
                    top 90px
                +below(575px)
                    top 60px
            &.hex-2
                top 0
                left 260px
                +between(575px, 992px)
                    top 0
                    left 155px
                +below(575px)
                    top 0
                    left 105px
            &.hex-3
                top 300px
                left 260px
                +between(575px, 992px)
                    top 180px
                    left 155px
                +below(575px)
                    top 120px
                    left 105px
            &.hex-4
                top 150px
                left 520px
                +between(575px, 992px)
                    top 90px
                    left 310px
                +below(575px)
                    top 60px
                    left 210px
            &.hex-5
                top 0
                left 780px
                +between(992px, 1400px)
                    top 450px
                    left 0
                +between(575px, 992px)
                    top 270px
                    left 0px
                +below(575px)
                    top 180px
                    left 0px
            &.hex-6
                top 300px
                left 780px
                +between(992px, 1400px)
                    top 450px
                    left 520px
                +between(575px, 992px)
                    top 270px
                    left 310px
                +below(575px)
                    top 180px
                    left 210px
            &.hex-7
                top 150px
                left 1040px
                +between(992px, 1400px)
                    top 600px
                    left 260px
                +between(575px, 992px)
                    top 365px
                    left 155px
                +below(575px)
                    top 240px
                    left 105px

.profile-pic
    +below(479px)
        padding-left 35px
        padding-right 35px
        padding-top 30px

        
/*Veggieburger---*/
@import "includes/veggieburger.styl"
/*Navigation*/
.navbar
    background-color transparent
    a 
        color $off-white
        font-family $font-stack-headers
        font-size 3rem
        font-weight 700
        text-transform uppercase
        &:hover, &:focus
            color $yellow
.navbar-nav 
    display flex
    justify-content center
    text-align center
    align-items center
    width 100%
    height 100vh  
    li:last-child .dropdown-menu
        right 0
        left auto
    
.navbar-toggler
    position relative
    z-index 10
    margin-top 15px
    border 0
    border-radius 50%
    height 54px
    padding-bottom 0
    width 54px
    &:hover, &:focus
        outline 0
.search-box
    display inline-flex
    .search-form
        margin-right 10px
        
.navbar-toggler 
    outline 0
    &:hover, &:focus
        cursor pointer
        .icon-bar 
            transition()
    .icon-bar 
        background-color $off-white
        transition()
        width 28px
        height 3px
        display block
        margin-bottom 5px

.offcanvas-collapse 
    position fixed
    top 0
    bottom 0
    height 100vh
    width 100%
    margin-top 0
    padding-right 1rem
    padding-left 1rem
    overflow-y auto
    background-color $orange-transparent
    transition -webkit-transform .3s ease-in-out
    transition transform .3s ease-in-out
    transition transform .3s ease-in-out, -webkit-transform .3s ease-in-out
    -webkit-transform translateX(100%)
    transform translateX(100%)
    &.open 
        -webkit-transform translateX(-1rem)
        transform translateX(-1rem)
/*html,
body 
  overflow-x hidden*//* Prevent scroll on narrow devices */

.nav-scroller 
    position relative
    z-index 2
    height 2.75rem
    overflow-y hidden
    .nav 
        display -webkit-box
        display flex
        flex-wrap nowrap
        padding-bottom 1rem
        margin-top -1px
        overflow-x auto
        text-align center
        white-space nowrap
        -webkit-overflow-scrolling touch


.nav-underline .nav-link 
    padding-top .75rem
    padding-bottom .75rem
    font-size .875rem

.lh-100 
    line-height 1
.lh-125 
    line-height 1.25
.lh-150 
    line-height 1.5
                    
/*Sidebars-----------------------*/

/*Secondary---------------------------------*/
body
    .jumbotron
        border-radius 0
        
.secondary, .single-projects
    .jumbotron
        height 450px
        background-image url('http://via.placeholder.com/1920x450')
        background-img(top, center)
    .project-intro
        padding-bottom 50px
        +below(767px)
            padding-bottom 0
        

section.main-title.no-jumbotron
    padding 180px 0 0 0

/*Footer*/
footer
    position relative
    background-color $white
    a
        color $white
        transition-all()
        &:hover, &:focus
            text-decoration none
            color $white
            transition-all()
    .footer-top
        background-color $medium-grey
        padding 30px 0
        .footer-menu
            float right
            font-size 1.2rem
            margin-top 155px
            ul 
                list-style-type none
                padding-left 0
                li 
                    display inline-block
                    margin-left 30px
                    a 
                        color $dark-grey
                        transition-all()
                        &:hover, &:focus
                            color $blue
                            transition-all()
        border-bottom 8px solid $dark-grey
        .footer-one
            color $white
            overflow hidden
            width 100%
            a.phone
                font-weight 900
                color $white
            ul
                color $white
                .company
                    font-weight 700
                font-size 1.125rem
                float left
                list-style-type none
                padding 0 0 10px 0
                a 
                    font-weight 700
                    color $dark-grey
                    transition-all()
                    &:hover, &:focus
                        color $blue
                        transition-all()
            .btn
                float left
                font-size 0.75rem
                clear both
    .footer-bottom
        background-color $dark-grey
        color $white
        padding 12px 0
        p
            font-size 1.125
            margin-bottom 0
        .social-container
            display flex
            flex-direction row
            align-items flex-end
            justify-content flex-end
            .social
                list-style-type none
                margin-bottom 0
                padding-left 0
                li
                    float left
                    margin 0 0 0 10px
                    a 
                        font-size 1.75rem
                        transition-all()
                        &:hover, &:focus
                            color $blue
                            transition-all()
                ul
                    padding-top 55px
        .kerry
            color white
        .links
            padding-top 15px
            ul 
                float right
            p
                float left
            li
                float left
                margin 0 0 0 25px
            a
                font-size 1rem
        .copyright
            margin 8px 0 0 0
            font-size 1rem
    .multi-line-divder-footer
        hr
            margin 2px 0 0 0
            &.line-1
                border-bottom 4px solid $dark-grey

            &.line-2
                border-bottom 2px solid $dark-grey

/*Home Page*/                
        
/*secondary*/
section.main-title
    padding 40px 0
    
.secondary, .single-projects
    .sidebar
        ul 
            padding-left 16px
            list-style none
            li 
                position relative
                a 
                    font-family $font-stack
                    width 100%
                    position relative
                    span 
                        padding-left 20px
                    &:hover, &:focus
                        color $yellow
                        transform rotate(0deg)
                        transition all .6s ease-in-out
                        &:before
                            transition all .6s ease-in-out
                            transform rotate(-360deg)
                    &:before
                        position absolute
                        top 24px
                        content "\e900"
                        font-size .8rem
                        height 12.81px
                        width 12.81px
                        color $yellow
                        font-family 'icomoon' !important
                        speak none
                        font-style normal
                        font-weight normal
                        font-variant normal
                        text-transform none
                        line-height 1
                        -webkit-font-smoothing antialiased
                        -moz-osx-font-smoothing grayscale
                .list-text
                    text-indent 15px
    .sidebar
        ul 
            list-style-type none
            padding-left 15px
            margin-top 0
            li 
                border-top 1px solid #ced4da
                &:before
                    margin-left 0
                &:first-child
                    border-top 0
                    margin-top -5px
                    a 
                        padding-top 0
                        &:before
                            top 9px
                        
                a
                    color $white
                    font-family $font-stack
                    font-size responsive 1rem 1.2rem
                    font-range 768px 992px
                    font-weight 700
                    padding 15px 0
                    display inline-flex
                    text-transform uppercase
                    transition-all() 
                    &:focus, &:hover
                        display inline-flex
                        transition-all() 
        
/*Utilities*/

/*Form--*/
.wpcf7
    label
        display none
        width 100%
    input[type=text], input[type=email]
        border-radius 0
        background-color transparent
        border-top 0
        border-left 0
        border-right 0
        border-bottom 5px solid $yellow
        box-shadow none
        transition-all()
        width 100%
        &.form-control
            color $white
            width 100%
            &:focus, &::placeholder
                color $white
            &:focus
                background-color $overlay-black
                transition-all()
    input[type="submit"]
        background-color transparent
        padding 8px 12px
        &:hover, &:focus
            background-color $yellow
            cursor pointer
    textarea
        border-radius 0
        background-color transparent
        border 5px solid $yellow
        box-shadow none
        color $white
        transition-all()
        &:focus, &::placeholder
            color $white
            background-color transparent
            outline $yellow
            border-color $yellow
        &:focus
            background-color $overlay-black
            transition-all()
/*Responsive-------------------------*/ 

@media (max-width: 576px)

    .multi-callout
        .card
            margin-bottom 100px
    .main-callout 
        figure
            h1
                font-size 2.5rem
            width 90%
            p
                font-size 1rem
    .cover-img
        max-height 300px
    .secondary .single-projects
        .jumbotron
            height 300px
        .main
            padding 30px 0
                
@media (max-width: 767px)
    .info-text
        padding 30px 15px
    .secondary, .single-projects
        article
            margin-bottom 30px
        .sidebar
            ul 
                border-left 0
                padding-left 0
        .jumbotron
            margin-bottom 0
    section.main-title.no-jumbotron
        padding 100px 0 0 0
    footer
        .footer-bottom
            .social-container
                align-items flex-start
                justify-content flex-start
    

@media (min-width: 577px) and (max-width: 767px)
    
    .multi-callout
        .card
            margin-bottom 100px
    .main-callout 
        figure
            width 80%
    .cover-img
        max-height 375px
    .secondary .single-projects
        .jumbotron
            height 375px
    
            
@media (min-width: 768px) and (max-width: 991px)
    .top-nav
        margin-left 0
        #menu-top-nav-1
            flex-direction column
            li 
                a 
                    padding 0.5rem 1rem 0.5rem 0
                    display block
        
@media (min-width: 992px) and (max-width: 1199px)
    .placeholder
        border 1px

@media (max-width: 991px)
    header
        .navbar
            padding-top 15px
        .bottom-area
            display block
            .widget_search
                display none
            .widget_nav_menu
                display block
        .top-area
            padding-top 15px
            .widget_nav_menu
                display none
        .top-nav
            margin-left 0
            #menu-top-nav-1
                flex-direction column
                li
                    a
                        padding 0.5rem 1rem 0.5rem 0
                        display block

/*Animation-*/
.animated 
  animation-duration 1s
  animation-fill-mode inherit

.animated.infinite
  animation-iteration-count infinite

.pulse
    animation play .8s steps(10) infinite
    animation-name pulse
    
@keyframes pulse 
  from 
    -webkit-transform scale3d(1, 1, 1)
    transform scale3d(1, 1, 1)
  

  50% 
    -webkit-transform scale3d(1.05, 1.05, 1.05)
    transform scale3d(1.15, 1.15, 1.15)
  

  to 
    -webkit-transform scale3d(1, 1, 1)
    transform scale3d(1, 1, 1)
    
.bounce
    animation-name bounce
    transform-origin center bottom
    
@keyframes bounce
    from,
    20%,
    53%,
    80%,
    to
        animation-timing-function cubic-bezier(0.215, 0.61, 0.355, 1)
        transform translate3d(0, 0, 0)

    40%,
    43%
        animation-timing-function cubic-bezier(0.755, 0.05, 0.855, 0.06)
        transform translate3d(0, -20px, 0)

    70%
        animation-timing-function cubic-bezier(0.755, 0.05, 0.855, 0.06)
        transform translate3d(0, -15px, 0)

    90%
        transform translate3d(0, -5px, 0)

@keyframes bounceInDown 
    from,
    60%,
    75%,
    90%,
    to
        animation-timing-function cubic-bezier(0.215, 0.61, 0.355, 1)
        animation-delay .5s

    0%
        opacity 0
        transform translate3d(0, -600px, 0)

    60%
        animation-delay 2s
        opacity 1
        transform translate3d(0, 25px, 0)

    75%
        transform translate3d(0, -10px, 0)

    90%
        transform translate3d(0, 5px, 0)

    to
        transform translate3d(0, 0, 0)

.bounceInDown
    animation-name bounceInDown
    animation-fill-mode backwards
    animation-delay 2s