.display-1
    font-size $display1-size
    font-weight $display1-weight
    line-height 1.1

.display-2
    font-size $display2-size
    font-weight $display2-weight
    line-height 1.1

.display-3
    font-size $display3-size
    font-weight $display3-weight
    line-height 1.1

.display-4
    font-size $display4-size
    font-weight $display4-weight
    line-height 1.1