.list-group
    display -ms-flexbox
    display flex
    -ms-flex-direction column
    flex-direction column
    padding-left 0
    margin-bottom 0

.list-group-item-action
    width 100%
    color #495057
    text-align inherit
    &:active
        color #212529
        background-color #e9ecef

.list-group-item-action:focus, .list-group-item-action:hover
    color #495057
    text-decoration none
    background-color $light

.list-group-item
    position relative
    display block
    padding 0.75rem 1.25rem
    margin-bottom -1px
    background-color $white
    border 1px solid rgba(0, 0, 0, 0.125)
    &:first-child
        border-top-left-radius 0.25rem
        border-top-right-radius 0.25rem
    &:last-child
        margin-bottom 0
        border-bottom-right-radius 0.25rem
        border-bottom-left-radius 0.25rem
    &.active
        z-index 2
        color $white
        background-color $primary
        border-color $primary

.list-group-item:focus, .list-group-item:hover
    text-decoration none

.list-group-item.disabled, .list-group-item:disabled
    color $secondary
    background-color $white

.list-group-flush
    .list-group-item
        border-right 0
        border-left 0
        border-radius 0
    &:first-child
        .list-group-item
            &:first-child
                border-top 0
    &:last-child
        .list-group-item
            &:last-child
                border-bottom 0

.list-group-item-primary
    color #004085
    background-color #b8daff

a.list-group-item-primary,
button.list-group-item-primary
    color #004085

a.list-group-item-primary:focus, a.list-group-item-primary:hover,
button.list-group-item-primary:focus,
button.list-group-item-primary:hover
    color #004085
    background-color #9fcdff

a.list-group-item-primary.active,
button.list-group-item-primary.active
    color $white
    background-color #004085
    border-color #004085

.list-group-item-secondary
    color #464a4e
    background-color #dddfe2

a.list-group-item-secondary,
button.list-group-item-secondary
    color #464a4e

a.list-group-item-secondary:focus, a.list-group-item-secondary:hover,
button.list-group-item-secondary:focus,
button.list-group-item-secondary:hover
    color #464a4e
    background-color #cfd2d6

a.list-group-item-secondary.active,
button.list-group-item-secondary.active
    color $white
    background-color #464a4e
    border-color #464a4e

.list-group-item-success
    color #155724
    background-color #c3e6cb

a.list-group-item-success,
button.list-group-item-success
    color #155724

a.list-group-item-success:focus, a.list-group-item-success:hover,
button.list-group-item-success:focus,
button.list-group-item-success:hover
    color #155724
    background-color #b1dfbb

a.list-group-item-success.active,
button.list-group-item-success.active
    color $white
    background-color #155724
    border-color #155724

.list-group-item-info
    color #0c5460
    background-color #bee5eb

a.list-group-item-info,
button.list-group-item-info
    color #0c5460

a.list-group-item-info:focus, a.list-group-item-info:hover,
button.list-group-item-info:focus,
button.list-group-item-info:hover
    color #0c5460
    background-color #abdde5

a.list-group-item-info.active,
button.list-group-item-info.active
    color $white
    background-color #0c5460
    border-color #0c5460

.list-group-item-warning
    color #856404
    background-color #ffeeba

a.list-group-item-warning,
button.list-group-item-warning
    color #856404

a.list-group-item-warning:focus, a.list-group-item-warning:hover,
button.list-group-item-warning:focus,
button.list-group-item-warning:hover
    color #856404
    background-color #ffe8a1

a.list-group-item-warning.active,
button.list-group-item-warning.active
    color $white
    background-color #856404
    border-color #856404

.list-group-item-danger
    color #721c24
    background-color #f5c6cb

a.list-group-item-danger,
button.list-group-item-danger
    color #721c24

a.list-group-item-danger:focus, a.list-group-item-danger:hover,
button.list-group-item-danger:focus,
button.list-group-item-danger:hover
    color #721c24
    background-color #f1b0b7

a.list-group-item-danger.active,
button.list-group-item-danger.active
    color $white
    background-color #721c24
    border-color #721c24

.list-group-item-light
    color #818182
    background-color #fdfdfe

a.list-group-item-light,
button.list-group-item-light
    color #818182

a.list-group-item-light:focus, a.list-group-item-light:hover,
button.list-group-item-light:focus,
button.list-group-item-light:hover
    color #818182
    background-color #ececf6

a.list-group-item-light.active,
button.list-group-item-light.active
    color $white
    background-color #818182
    border-color #818182

.list-group-item-dark
    color #1b1e21
    background-color #c6c8ca

a.list-group-item-dark,
button.list-group-item-dark
    color #1b1e21

a.list-group-item-dark:focus, a.list-group-item-dark:hover,
button.list-group-item-dark:focus,
button.list-group-item-dark:hover
    color #1b1e21
    background-color #b9bbbe

a.list-group-item-dark.active,
button.list-group-item-dark.active
    color $white
    background-color #1b1e21
    border-color #1b1e21