.close
    float right
    font-size 1.5rem
    font-weight $font-weight-bold
    line-height 1
    color $black
    text-shadow 0 1px 0 $white
    opacity .5

.close:focus, .close:hover
    color $black
    text-decoration none
    opacity .75

button
    &.close
        padding 0
        background transparent
        border 0
        -webkit-appearance none