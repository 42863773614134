.card
    position relative
    display -ms-flexbox
    display flex
    -ms-flex-direction column
    flex-direction column
    min-width 0
    word-wrap break-word
    background-color $white
    background-clip border-box
    border 1px solid rgba(0, 0, 0, 0.125)
    border-radius 0.25rem
    & > .list-group
        &:first-child
            .list-group-item
                &:first-child
                    border-top-left-radius 0.25rem
                    border-top-right-radius 0.25rem
        &:last-child
            .list-group-item
                &:last-child
                    border-bottom-right-radius 0.25rem
                    border-bottom-left-radius 0.25rem

.card-body
    -ms-flex 1 1 auto
    flex 1 1 auto
    padding 1.25rem

.card-title
    margin-bottom 0.75rem

.card-subtitle
    margin-top -0.375rem
    margin-bottom 0

.card-text
    &:last-child
        margin-bottom 0

.card-link
    &:hover
        text-decoration none
    & + .card-link
        margin-left 1.25rem

.card-header
    padding 0.75rem 1.25rem
    margin-bottom 0
    background-color rgba(0, 0, 0, 0.03)
    border-bottom 1px solid rgba(0, 0, 0, 0.125)
    &:first-child
        border-radius calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0

.card-footer
    padding 0.75rem 1.25rem
    background-color rgba(0, 0, 0, 0.03)
    border-top 1px solid rgba(0, 0, 0, 0.125)
    &:last-child
        border-radius 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px)

.card-header-tabs
    margin-right -0.625rem
    margin-bottom -0.75rem
    margin-left -0.625rem
    border-bottom 0

.card-header-pills
    margin-right -0.625rem
    margin-left -0.625rem

.card-img-overlay
    position absolute
    top 0
    right 0
    bottom 0
    left 0
    padding 1.25rem

.card-img
    width 100%
    border-radius calc(0.25rem - 1px)

.card-img-top
    width 100%
    border-top-left-radius calc(0.25rem - 1px)
    border-top-right-radius calc(0.25rem - 1px)

.card-img-bottom
    width 100%
    border-bottom-right-radius calc(0.25rem - 1px)
    border-bottom-left-radius calc(0.25rem - 1px)

@media (min-width: $grid-sm)
    .card-deck
        display -ms-flexbox
        display flex
        -ms-flex-flow row wrap
        flex-flow row wrap
        margin-right -15px
        margin-left -15px
        .card
            display -ms-flexbox
            display flex
            -ms-flex 1 0 0%
            flex 1 0 0%
            -ms-flex-direction column
            flex-direction column
            margin-right 15px
            margin-left 15px
    .card-group
        display -ms-flexbox
        display flex
        -ms-flex-flow row wrap
        flex-flow row wrap
        .card
            -ms-flex 1 0 0%
            flex 1 0 0%
            & + .card
                margin-left 0
                border-left 0
            &:first-child
                border-top-right-radius 0
                border-bottom-right-radius 0
                .card-img-top
                    border-top-right-radius 0
                .card-img-bottom
                    border-bottom-right-radius 0
            &:last-child
                border-top-left-radius 0
                border-bottom-left-radius 0
                .card-img-top
                    border-top-left-radius 0
                .card-img-bottom
                    border-bottom-left-radius 0
            &:not(:first-child):not(:last-child)
                border-radius 0
    .card-group .card:not(:first-child):not(:last-child) .card-img-top, .card-group .card:not(:first-child):not(:last-child) .card-img-bottom
        border-radius 0
    .card-columns
        -webkit-column-count 3
        column-count 3
        -webkit-column-gap 1.25rem
        column-gap 1.25rem
        .card
            display inline-block
            width 100%
    
.card-columns
    .card
        margin-bottom 0.75rem
    