.badge
    display inline-block
    padding 0.25em 0.4em
    font-size 75%
    font-weight $font-weight-bold
    line-height 1
    color $white
    text-align center
    white-space nowrap
    vertical-align baseline
    border-radius 0.25rem
    &:empty
        display none

.btn
    .badge
        position relative
        top -1px

.badge-pill
    padding-right 0.6em
    padding-left 0.6em
    border-radius 10rem

.badge-primary
    color $white
    background-color $primary

.badge-primary[href]:focus, .badge-primary[href]:hover
    color $white
    text-decoration none
    background-color $primaryHov

.badge-secondary
    color $white
    background-color $secondary

.badge-secondary[href]:focus, .badge-secondary[href]:hover
    color $white
    text-decoration none
    background-color $secondaryHov

.badge-success
    color $white
    background-color $success

.badge-success[href]:focus, .badge-success[href]:hover
    color $white
    text-decoration none
    background-color $successHov

.badge-info
    color $white
    background-color $info

.badge-info[href]:focus, .badge-info[href]:hover
    color $white
    text-decoration none
    background-color $infoHov

.badge-warning
    color #111
    background-color $warning

.badge-warning[href]:focus, .badge-warning[href]:hover
    color #111
    text-decoration none
    background-color $warningHov

.badge-danger
    color $white
    background-color $danger

.badge-danger[href]:focus, .badge-danger[href]:hover
    color $white
    text-decoration none
    background-color $dangerHov

.badge-light
    color #111
    background-color $light

.badge-light[href]:focus, .badge-light[href]:hover
    color #111
    text-decoration none
    background-color $lightHov

.badge-dark
    color $white
    background-color $dark

.badge-dark[href]:focus, .badge-dark[href]:hover
    color $white
    text-decoration none
    background-color $darkHov