/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */
// Settings
// ==================================================
$veggeieburger-padding-x = 0
$veggeieburger-padding-y = 0
$veggeieburger-layer-width = 32px
$veggeieburger-layer-height = 3px
$veggeieburger-layer-spacing = 6px
$veggeieburger-layer-color = white
$veggeieburger-layer-border-radius = 0
$veggeieburger-hover-opacity = 0.7
$veggeieburger-hover-transition-duration = 0.15s
$veggeieburger-hover-transition-timing-function = linear
// To use CSS filters as the hover effect instead of opacity,
// set $veggeieburger-hover-use-filter as true and
// change the value of $veggeieburger-hover-filter accordingly.
$veggeieburger-hover-use-filter = false
$veggeieburger-hover-filter = opacity(50%)

.veggeieburger
    padding $veggeieburger-padding-y $veggeieburger-padding-x
    display inline-block
    cursor pointer
    transition-property opacity, filter
    transition-duration $veggeieburger-hover-transition-duration
    transition-timing-function $veggeieburger-hover-transition-timing-function
    // Normalize (<button>)
    font inherit
    color inherit
    text-transform none
    background-color transparent
    border 0
    margin 0
    overflow visible
    &hover
        if $veggeieburger-hover-use-filter == true
            filter $veggeieburger-hover-filter
        else
            opacity $veggeieburger-hover-opacity
.veggeieburger-box
    width $veggeieburger-layer-width
    height ($veggeieburger-layer-height * 3 + $veggeieburger-layer-spacing * 2)
    display inline-block
    position relative
    margin-top 5px
.veggeieburger-inner
    display block
    top 50%
    margin-top ($veggeieburger-layer-height / -2)
    &,
    &:before,
    &:after
        width $veggeieburger-layer-width
        height $veggeieburger-layer-height
        background-color $veggeieburger-layer-color
        border-radius $veggeieburger-layer-border-radius
        position absolute
        transition-property transform
        transition-duration 0.15s
        transition-timing-function ease
    &:before,
    &:after
        content ""
        display block
    &:before
        top (($veggeieburger-layer-spacing + $veggeieburger-layer-height) * -1)
    &:after
        bottom (($veggeieburger-layer-spacing + $veggeieburger-layer-height) * -1)
        
  /*
   * Emphatic
   */
.veggeieburger--emphatic
    overflow hidden
    .veggeieburger-inner
        transition background-color 0.125s 0.175s ease-in
        &::before
            left 0
            transition transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, left 0.125s 0.175s ease-in
        &::after
            top 9px
            right 0
            transition transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, right 0.125s 0.175s ease-in
    &.is-active
        .veggeieburger-inner
            transition-delay 0s
            transition-timing-function ease-out
            background-color transparent
            &::before
                left -80px
                top -80px
                transform translate3d(80px, 80px, 0) rotate(45deg)
                transition left 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1)
            &::after
                right -80px
                top -80px
                transform translate3d(-80px, 80px, 0) rotate(-45deg)
                transition right 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1)