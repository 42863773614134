.rounded
    border-radius 0.25rem !important

.rounded-top
    border-top-left-radius 0.25rem !important
    border-top-right-radius 0.25rem !important

.rounded-right
    border-top-right-radius 0.25rem !important
    border-bottom-right-radius 0.25rem !important

.rounded-bottom
    border-bottom-right-radius 0.25rem !important
    border-bottom-left-radius 0.25rem !important

.rounded-left
    border-top-left-radius 0.25rem !important
    border-bottom-left-radius 0.25rem !important

.rounded-circle
    border-radius 50%

.rounded-0
    border-radius 0