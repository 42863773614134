.modal-open
    overflow hidden
    .modal
        overflow-x hidden
        overflow-y auto

.modal
    position fixed
    top 0
    right 0
    bottom 0
    left 0
    z-index 1050
    display none
    overflow hidden
    outline 0
    &.fade
        .modal-dialog
            transition -webkit-transform 0.3s ease-out
            transition transform 0.3s ease-out
            transition transform 0.3s ease-out, -webkit-transform 0.3s ease-out
            -webkit-transform translate(0, -25%)
            transform translate(0, -25%)
    &.show
        .modal-dialog
            -webkit-transform translate(0, 0)
            transform translate(0, 0)

.modal-dialog
    position relative
    width auto
    margin 10px

.modal-content
    position relative
    display -ms-flexbox
    display flex
    -ms-flex-direction column
    flex-direction column
    background-color $white
    background-clip padding-box
    border 1px solid rgba(0, 0, 0, 0.2)
    border-radius 0.3rem
    outline 0

.modal-backdrop
    position fixed
    top 0
    right 0
    bottom 0
    left 0
    z-index 1040
    background-color $black
    &.fade
        opacity 0
    &.show
        opacity 0.5

.modal-header
    display -ms-flexbox
    display flex
    -ms-flex-align center
    align-items center
    -ms-flex-pack justify
    justify-content space-between
    padding 15px
    border-bottom 1px solid #e9ecef

.modal-title
    margin-bottom 0
    line-height 1.5

.modal-body
    position relative
    -ms-flex 1 1 auto
    flex 1 1 auto
    padding 15px

.modal-footer
    display -ms-flexbox
    display flex
    -ms-flex-align center
    align-items center
    -ms-flex-pack end
    justify-content flex-end
    padding 15px
    border-top 1px solid #e9ecef
    & > :not(:first-child)
        margin-left .25rem
    & > :not(:last-child)
        margin-right .25rem

.modal-scrollbar-measure
    position absolute
    top -9999px
    width 50px
    height 50px
    overflow scroll
    
.modal-dialog-centered
    display flex
    align-items center
    min-height calc(100% - (.5rem * 2))
    .modal-content
        width 100%
    
@media (min-width: 576px)
    .modal-dialog-centered
        min-height calc(100% - (1.75rem * 2))


@media (min-width: $grid-sm)
    .modal-dialog
        max-width 500px
        margin 30px auto
    .modal-sm
        max-width 300px

@media (min-width: $grid-lg)
    .modal-lg
        max-width 800px